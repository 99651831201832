import { WarningFilled } from "@ant-design/icons";
import Button from "../../components/atom/Button";
import { Link } from "react-router-dom";

export function Unauthorized() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <WarningFilled className="text-7xl text-red-700" height={92} />
      You are not authorized to view this page!
      <Link to="/">
        <Button className="mt-4" type="primary">
          Go Home
        </Button>
      </Link>
    </div>
  );
}
