import { supabase } from "../supabaseClient";

export async function fetchMaintenances(model_id) {
  const response = await supabase
    .from("maintenance")
    .select("*")
    .eq("model_id", model_id);
  return response;
}
export async function addMaintenanceTable(values) {
  const response = await supabase
    .from("maintenance")
    .insert([values])
    .select();
  return response;
}