import React from "react";
import { DatePicker, Space } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const CustomDatePicker = ({ value, onChange }) => {
  const handleChange = (dates, dateStrings) => {
    if (onChange) {
      onChange(dates, dateStrings);
    }
  };

  const formatValue = (value) => {
    if (!value) return [];
    return value.map((v) => (v ? dayjs(v) : null));
  };

  return (
    <Space direction="vertical" size={12} className="w-full">
      <RangePicker value={formatValue(value)} onChange={handleChange} />
    </Space>
  );
};

CustomDatePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default CustomDatePicker;
