import { supabase } from "../supabaseClient";

// export async function fetchAllocations() {
//   const response = await supabase.from("allocation").select("*");
//   return response;
// }

// export async function addAllocationHistory(values) {
//   const response = await supabase.from("allocation").insert([values]);
//   return response;
// }

export async function fetchAllocations(model_id) {
  const response = await supabase
    .from("allocation")
    .select("*, driver(*), vehicle(*)")
    .eq("model_id", model_id).not("driver", "is", null);
  return response;
}
export async function addAllocationHistory(values) {
  const response = await supabase
    .from("allocation")
    .insert([values])
    .select();
  return response;
}
