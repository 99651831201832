import { BrowserRouter, Route, Routes } from "react-router-dom";
import VehicleList from "./../ui/vehicle/vehicles";
import VehicleForm from "../ui/vehicle/vehicle_form";
import SignIn from "../ui/auth/sign_in";
import ProtectedRoute from "./protectedRoute";
import SignUp from "../ui/auth/signup";
import SidebarHeaderlayout from "../layout/index";
import AuthenticationRoute from "./authenticationRoute";
import DriverList from "../ui/driver/drivers";
import DriverForm from "../ui/driver/driver_form";
import VendorList from "../ui/vendor/vendors";
import VendorForm from "../ui/vendor/vendor_form";
import SingleVehicle from "../ui/vehicle/single_vehicle";
import { AuthProvider } from "../providers/auth.provider";
import SingleDriver from "../ui/driver/single_driver";
import DriverKycForm from "../ui/driver/driver_kyc_form";
import { UserList } from "../ui/user/UserList";

export default function AppRoute() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<AuthenticationRoute />}>
            <Route path="/sign_in" element={<SignIn />} />
            <Route path="/sign_up" element={<SignUp />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={<SidebarHeaderlayout />}>
              <Route path="/" element={<VehicleList />} />
              <Route path="/vehicle/:vehicle_id" element={<SingleVehicle />} />
              <Route path="/add-fleet" element={<VehicleForm />} />
              <Route path="/add-driver" element={<DriverForm />} />
              <Route path="/drivers/:driver_id" element={<SingleDriver />} />
              <Route path="/drivers" element={<DriverList />} />
              <Route path="/vendor" element={<VendorList />} />
              <Route path="/add-vendor" element={<VendorForm />} />
              <Route
                path="/update-kyc/:driver_id"
                element={<DriverKycForm />}
              />
              <Route element={<ProtectedRoute adminProtected />}>
                <Route path="/user/list" element={<UserList />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
