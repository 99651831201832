import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useProtectedAuth from "../hooks/useProtectedAuth";
import Spinner from "../components/atom/Spinner";
import { cookieKey } from "../constants/cookies.constant";
import Cookies from "js-cookie";
import { userRole } from "../constants/auth.constant";
import { VerifyingUser } from "../ui/shared/Verifying";
import { getUserRole } from "../backend/service/auth.service";
import { Unauthorized } from "../ui/shared/Unauthorized";

const ProtectedRoute = ({ adminProtected }) => {
  const navigate = useNavigate();
  const { user, loading } = useProtectedAuth();
  const [_, rerender] = useState();

  const role = Cookies.get(cookieKey.userRole);
  const isSuperuser = role === userRole.superAdmin;
  const isVerified = Boolean(role);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const { data: userRole } = await getUserRole(user?.user?.id);
        Cookies.set(cookieKey.userRole, userRole[0]?.role?.role ?? "");
        rerender(userRole);
      } catch (err) {
        // Do nothing
      }
    };

    if (!isVerified && user?.user?.id) fetchUserRole();
  }, [user, isVerified]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (!user) {
    navigate("/sign_in", { replace: true });
    return null;
  }

  if (adminProtected) return isSuperuser ? <Outlet /> : <Unauthorized />;
  return isVerified ? <Outlet /> : <VerifyingUser />;
};

export default ProtectedRoute;
