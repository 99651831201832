import React from "react";
import { Popconfirm } from "antd";

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {string} [description]
 * @property {string} [okText]
 * @property {string} [cancelText]
 * @property {React.ReactNode} [children]
 * @property {() => void} [onConfirm]
 */

/**
 * @param {Props} props
 * @returns {JSX.Element}
 */
const ConfirmPopup = ({
  title,
  description,
  okText = "Yes",
  cancelText = "No",
  onConfirm,
  children,
}) => {
  return (
    <Popconfirm
      title={title}
      description={description}
      okText={okText}
      onConfirm={onConfirm}
      cancelText={cancelText}
    >
      {children}
    </Popconfirm>
  );
};

export default ConfirmPopup;
