import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../components/atom/Input/index";
import ProfileUpload from "../../components/atom/ProfileUpload";
import CustomDragAndDropUpload from "../../components/atom/CustomDragAndDropUpload/index";
import Button from "../../components/atom/Button";
import { uploadKycDetails } from "../../backend/service/driver.service";
import { message } from "antd";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  selfie: Yup.mixed().required("Selfie is required"),
  pan_number: Yup.string().required("Pan number is required"),
  aadhaar_number: Yup.string().required("Aadhar number is required"),
  drivers_license: Yup.string().required("License is required"),
  aadhaar_card: Yup.string().required("Aadhar card is required"),
});

const DriverKycForm = () => {
  const [file, setFile] = useState({ file: null, preview: null });
  const [aadharImage, setAadharImage] = useState(null);
  const [dlImage, setDlImage] = useState(null);
  const [loading, setLoading] = useState(null);
  const { driver_id } = useParams();
  const navigate = useNavigate();

  const initialFormState = {
    selfie: file.preview ?? "",
    pan_number: "",
    aadhaar_number: "",
    drivers_license: dlImage ?? "",
    aadhaar_card: aadharImage ?? "",
    kyc_status: "DONE" ?? "",
  };

  const formik = useFormik({
    validationSchema,
    initialValues: initialFormState,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data, error } = await uploadKycDetails(values, driver_id);
        if (error) {
          throw error;
        }
        message.success("KYC updated successfully");
        navigate("/drivers", { replace: true });
        setLoading(false);
      } catch (error) {
        message.error(error?.message);
        setLoading(false);
      }
    },
  });

  return (
    <div className="p-6 font-family-Spartan flex flex-col">
      <h2 className="text-xl text-[#262728]">Upload KYC Documents</h2>
      <form
        className="bg-[#FFFFFF] rounded-md mt-4 p-5 "
        onSubmit={formik.handleSubmit}
      >
        <div>
          <ProfileUpload
            file={file}
            setFile={(file) => {
              setFile(file);
              formik.setFieldValue("selfie", file.preview);
            }}
            id="selfie"
            name="selfie"
            required={true}
            onBlur={formik.handleBlur}
          />
          <p className="text-red-400 mt-1 text-sm">
            {formik.touched.selfie && formik.errors.selfie}
          </p>
        </div>

        <div className="flex gap-2 mt-5">
          <label>
            Pan no:
            <CustomInput
              type="text"
              placeholder="Enter pan no "
              className="w-full py-2"
              name="pan_number"
              id="pan_number"
              required
              value={formik.values.pan_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pan_number && formik.errors.pan_number}
            />
          </label>
          <label>
            Aadhar no:
            <CustomInput
              type="text"
              placeholder="Enter aadhar "
              className="w-full py-2"
              name="aadhaar_number"
              id="aadhaar_number"
              required
              value={formik.values.aadhaar_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.aadhaar_number && formik.errors.aadhaar_number
              }
            />
          </label>
        </div>
        <div className="flex gap-2 w-full mt-4">
          <div className="w-full">
            <CustomDragAndDropUpload
              label="Driver Adhar Upload"
              setBase64Image={(file) => {
                setAadharImage(file);
                formik.setFieldValue("aadhaar_card", file);
              }}
              id="aadhaar_card"
              name="aadhaar_card"
              required={true}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-400 mt-1 text-sm">
              {formik.touched.aadhaar_card && formik.errors.aadhaar_card}
            </p>
          </div>
          <div className="w-full">
            <CustomDragAndDropUpload
              label="Driver License Upload"
              setBase64Image={(file) => {
                setDlImage(file);
                formik.setFieldValue("drivers_license", file);
              }}
              id="drivers_license"
              name="drivers_license"
              required={true}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-400 mt-1 text-sm">
              {formik.touched.drivers_license && formik.errors.drivers_license}
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-4 items-end">
          <Button type="primary" onClick={formik.handleSubmit}>
            {loading ? "Loading.." : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DriverKycForm;
