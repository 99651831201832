import React from "react";
import { Layout, Menu, Popconfirm } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { adminRoutes, layoutItems } from "../constants/layout.constant";
import RiderIcon from "../assets/images/RiderIcon.svg";
import { useAuth } from "../providers/auth.provider";
import { cookieKey } from "../constants/cookies.constant";
import Cookies from "js-cookie";
import { userRole } from "../constants/auth.constant";

const { Content, Sider } = Layout;

const Layouts = ({ children }) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const { logout } = useAuth();

  const role = Cookies.get(cookieKey.userRole);
  const isSuperadmin = role === userRole.superAdmin;

  return (
    <>
      <Layout hasSider className="mt-0.5 relative flex">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          theme="dark"
          className="flex flex-col justify-between h-screen top-0 bottom-0 z-[5] min-w-[240px]"
          style={{
            position: "sticky",
          }}
        >
          <h1 className="text-white items-center font-bold flex justify-center gap-3   py-3 px-4 cursor-pointer text-2xl">
            <span className="bg-[#F0F7FF] px-2 pb-0 pt-1  rounded-md">
              <img src={RiderIcon} alt="" />
            </span>
            RiderOS
          </h1>
          <div className="demo-logo-vertical  h-[85vh] flex flex-col  justify-between">
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[currentRoute]}
            >
              {layoutItems?.map((item) => (
                <Menu.Item key={item.url} icon={item.icon}>
                  <Link to={item.url}>{item.label}</Link>
                </Menu.Item>
              ))}
              {isSuperadmin && (
                <>
                  <p className="ml-5 mt-6 mb-4 text-slate-200 font-bold">
                    Administration
                  </p>
                  {adminRoutes.map(({ url, icon, label }) => (
                    <Menu.Item key={label} icon={icon}>
                      <Link to={url}>{label}</Link>
                    </Menu.Item>
                  ))}
                </>
              )}
            </Menu>
            <div className=" items-center h-[10vh] gap-2 px-3 py-2 rounded-3xl">
              <Popconfirm
                title="Logout"
                description="Are you sure to you want to logout?"
                okText="Yes"
                onConfirm={logout}
                cancelText="No"
              >
                <div className="items-stretch flex hover:bg-slate-600 justify-between gap-3 px-5 py-3 rounded-xl">
                  <div className="text-white cursor-pointer text-base leading-4 tracking-tight self-center grow whitespace-nowrap my-auto">
                    Log out
                  </div>
                </div>
              </Popconfirm>
            </div>
          </div>
        </Sider>
        <Layout>
          <Content className="lg:relative absolute top-0 left-0 w-full">
            <div className="bg-white w-full shadow-lg h-14"></div>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

Layouts.prototypes = {
  children: PropTypes.node.isRequired,
};
export default Layouts;
