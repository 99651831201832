import {
  CarOutlined,
  HomeFilled,
  PlusCircleOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

export const layoutItems = [
  {
    label: "Home",
    url: "/home",
    icon: <HomeFilled />,
  },
  {
    label: "Vehicles",
    url: "/",
    icon: <PlusCircleOutlined />,
  },
  {
    label: "Driver",
    url: "/drivers",
    icon: <UserOutlined />,
  },
  {
    label: "Vendor",
    url: "/vendor",
    icon: <CarOutlined />,
  },
];

export const adminRoutes = [
  {
    label: "Users",
    url: "/user/list",
    icon: <UserSwitchOutlined />,
  },
];
