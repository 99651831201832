import React, { createContext, useContext, useEffect, useState } from "react";
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { CarOutlined } from "@ant-design/icons";
import Spinner from "../../components/atom/Spinner";
import { fetchVehicles, getDriverOptions, updateVehicle } from "../../backend/service/vehicle.service";
import { vehiclesColumns } from "../../constants/vehicle.constant";
import { addAllocationHistory } from "../../backend/service/allocation.service";

const VehicleList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data, error } = await fetchVehicles();
      if (error) {
        throw error;
      }
      const vehicles = data.map(vehicle => {
        const v = {
          ...vehicle,
          driver_name: vehicle?.driver?.name,
          driver_id: vehicle?.driver?.id
        }
        delete v?.driver
        return v;
      });
      setVehicles(vehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: vehiclesColumns, data: vehicles });

  return (
    <div className="p-5">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex justify-between py-5">
            <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
              Existing Vehicles
            </h2>
            <Link to="/add-fleet">
              <Button type="primary" icon={<CarOutlined />}>
                Add Fleet
              </Button>
            </Link>
            </div>
            <VehicleProvider vehicles={vehicles} setVehicles={setVehicles}>
              <table
                {...getTableProps()}
                style={{
                  border: "1px solid #f7e7e7",
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          style={{
                            borderBottom: "1px solid #f7e7e7",
                            background: "#dde2f7",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        key={row.values.model_id}
                        {...row.getRowProps()}
                        style={{
                          borderBottom: "1px solid #f7e7e7",
                          cursor: "pointer",
                        }}
                      >
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: "8px",
                              textAlign: "left",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </VehicleProvider>
        </div>
      )}
    </div>
  );
};

export default VehicleList;

const VehicleContext = createContext({
  getOptions: () => [],
  handleAssignDriver: () => null,
  handleUnassignDriver: () => null,
});

const VehicleProvider = ({ children, vehicles, setVehicles }) => {
  const [driverOptions, setDriverOptions] = useState([]);

  useEffect(() => {
    fetchDriverOptions();
      
  }, []);

  const fetchDriverOptions = async () => {
    try {
      const { data, error } = await getDriverOptions();
      if (error) {
        throw error;
      }
      setDriverOptions(data);
    } catch (error) {
      console.error("Error fetching driver options:", error.message);
    }
  };

  const getOptions = (vehicleId) => {
    let opt = [];
    const vehicle = vehicles?.find(v => v?.model_id === vehicleId); 
    if (vehicle) { 
      const label = vehicle?.driver_name;
      const value = vehicle?.driver_id;
      if (label && value) opt = [{ label, value }];
    }

    return opt.concat(driverOptions);
  };

  const handleAssignDriver = async (vehicleId, driverId) => {
    try {
      const { error } = await updateVehicle({ driver_id: driverId }, vehicleId);
      if (error) throw new Error(error);

      setVehicles(vehicles => vehicles.map(vehicle => {
        if (vehicle?.model_id === vehicleId) {
          return {
            ...vehicle,
            driver_name: driverOptions.find(d => d.value === driverId)?.label,
            driver_id: driverId
          }
        }
        return vehicle;
      }));
      addAllocationHistory({model_id: vehicleId, driver_id: driverId})
      fetchDriverOptions();
    } catch (error) {
      console.error("Error assigning driver:", error.message);
    }
  };

  const handleUnassignDriver = async (vehicleId) => {
    try {
      const { error } = await updateVehicle({ driver_id: null }, vehicleId);
      if (error) throw new Error(error);

      setVehicles(vehicles => vehicles.map(vehicle => {
        if (vehicle?.model_id === vehicleId) {
          return {
            ...vehicle,
            driver_name: null,
            driver_id: null
          }
        }
        return vehicle;
      }));
      addAllocationHistory({model_id: vehicleId, driver_id: null})
      fetchDriverOptions();
    } catch (error) {
      console.error("Error unassigning driver:", error.message);
    }
  };

  return (
	<VehicleContext.Provider
	  value={{ getOptions, handleAssignDriver, handleUnassignDriver }}
	>
	  {children}
	</VehicleContext.Provider>
  );
};

export const useVehicle = () => {
  const ctx = useContext(VehicleContext)

  if (!ctx) throw new Error("VehicleContext not in scope!");
  return ctx
}