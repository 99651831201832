import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useTable } from "react-table";
import { Button, message } from "antd";
import { CarOutlined } from "@ant-design/icons";
import PopUpModal from "../../components/atom/PopUpModal";
import CustomInput from "../../components/atom/Input/index";
import CustomDatePicker from "../../components/atom/DatePicker/index";
import Spinner from "../../components/atom/Spinner";
import {
  addMaintenanceTable,
  fetchMaintenances,
} from "../../backend/service/maintenance.service";
import { maitainancesColumns } from "../../constants/maitainance.constant";

const MaintenanceTable = () => {
  const [maintenances, setMaintenances] = useState([]);
  const [isMaintenanceModal, setIsMaintenanceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { vehicle_id } = useParams();

  const fetchMaintenancesData = async () => {
    setLoading(true);
    const abortController = new AbortController();
    try {
      const signal = abortController.signal;
      if (vehicle_id) {
        const { data, error } = await fetchMaintenances(vehicle_id, { signal });
        if (error) {
          throw error;
        }
        setMaintenances(data);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error fetching maintenances:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchMaintenancesData(signal);

    return () => {
      controller.abort();
    };
  }, [vehicle_id]);

  const showMaintenanceModal = () => {
    setIsMaintenanceModal(true);
  };

  const handleMaintenanceModalOk = () => {
    formik.handleSubmit();
  };

  const handleMaintenanceModalCancel = () => {
    setIsMaintenanceModal(false);
  };

  const maintenancesTable = useTable({
    columns: maitainancesColumns,
    data: maintenances,
  });

  const formik = useFormik({
    initialValues: {
      type: "",
      date: "",
      duration: [],
      payment: "",
      amount: "",
      model_id: vehicle_id,
    },
    // validationSchema: Yup.object({...}),
    onSubmit: async (values, { resetForm }) => {
      console.log("form values", values);
      setLoading(true);
      try {
        const formattedValues = {
          ...values,
          duration: values.duration.join("/"),
          amount: `Rs. ${values.amount}`,
        };
        if (vehicle_id) {
          const { data, error } = await addMaintenanceTable(formattedValues);
          console.log("data", data);
          if (error) {
            throw error;
          }
          message.success("Maintenance Data posted successfully");
          setIsMaintenanceModal(false); // Close the modal after successful submission
          fetchMaintenancesData(); // Refetch maintenances data
          resetForm();
        } else {
          throw new Error("Model ID is not provided");
        }
      } catch (error) {
        console.error("Error updating vehicle:", error);
        message.error("Error updating vehicle: " + error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="flex flex-col p-6">
      <div className="flex justify-between py-5">
        <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
          Maintenance
        </h2>

        <Button
          type="primary"
          icon={<CarOutlined />}
          onClick={showMaintenanceModal}
        >
          Add Fleet
        </Button>
        <PopUpModal
          title="Add Maintenance Modal"
          open={isMaintenanceModal}
          onOk={handleMaintenanceModalOk}
          onCancel={handleMaintenanceModalCancel}
        >
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label>
                Type:
                <CustomInput
                  type="text"
                  placeholder="Enter Type"
                  className="w-full py-2"
                  name="type"
                  id="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
              <label>
                Date:
                <CustomInput
                  type="date"
                  placeholder="Enter Date"
                  className="w-full py-2"
                  name="date"
                  id="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
              <label>
                Duration:
                <CustomDatePicker
                  value={formik.values.duration}
                  onChange={(dates, dateStrings) =>
                    formik.setFieldValue("duration", dateStrings)
                  }
                />
              </label>
              <label>
                Payment:
                <CustomInput
                  type="text"
                  placeholder="Payment"
                  className="w-full py-2"
                  name="payment"
                  id="payment"
                  value={formik.values.payment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
              <label>
                Amount:
                <CustomInput
                  type="text"
                  placeholder="Amount"
                  className="w-full py-2"
                  name="amount"
                  id="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
            </div>
          </form>
        </PopUpModal>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <table
          {...maintenancesTable.getTableProps()}
          style={{
            border: "1px solid #f7e7e7",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            {maintenancesTable.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "1px solid #f7e7e7",
                      background: "#dde2f7",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...maintenancesTable.getTableBodyProps()}>
            {maintenancesTable.rows.map((row) => {
              maintenancesTable.prepareRow(row);
              return (
                <tr
                  key={row.values.service_id}
                  {...row.getRowProps()}
                  style={{
                    borderBottom: "1px solid #f7e7e7",
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MaintenanceTable;
