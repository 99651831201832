import { supabase } from "../supabaseClient";

export async function userSignIn(values) {
  const response = await supabase.auth.signInWithPassword(values);
  return response;
}

export async function getUserRole(user_id) {
  const response = await supabase
    .from("user_roles")
    .select(
      `
    role (
      id,
      role
    )
  `
    )
    .eq("user_id", user_id);
  return response;
}

export async function postUserRole(payload) {
  const response = await supabase.from("user_roles").insert([payload]);
  return response;
}

export async function getUserForAuth() {
  const response = await supabase.auth.getUser();
  return response;
}

export async function userSignUp(values) {
  const response = await supabase.auth.signUp(values);
  return response;
}

export async function signOut() {
  await supabase.auth.signOut();
}
