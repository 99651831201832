import { useState } from "react";
import { Popover, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import Dropdown from "../components/atom/Dropdown";
import { useVehicle } from "../ui/vehicle/vehicles";
import Button from "../components/atom/Button";
import moment from "moment";

export const vehiclesColumns = [
  {
    Header: "Model ID",
    accessor: "model_id",
  },
  {
    Header: "Vehicle Model",
    accessor: "vehicle_model",
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Date Added",
    Cell: ({ row }) => {
      const dateAdded = row.original.date_added;
      return moment(dateAdded).format("MMMM D, YYYY");
    },
  },
  {
    Header: "Vehicle Number",
    accessor: "vehicle_number",
  },
  {
    Header: "Allocation Status",
    accessor: "allocation_status",
    Cell: ({ row }) => {
      const hasAllocatedDriver = row.original?.driver_id;
      let color = hasAllocatedDriver ? "green" : "red";
      const status = hasAllocatedDriver ? "Allocated" : "Not Allocated";

      return <Tag color={color}>{status}</Tag>;
    },
  },
  {
    Header: "Allocated Driver",
    Cell: ({ row }) => {
      const [otns, setOptns] = useState([]);
      const [open, setOpen] = useState(false);

      const { getOptions, handleAssignDriver, handleUnassignDriver } =
        useVehicle();

      const show = () => setOpen(true);
      const hide = () => setOpen(false);

      const {
        model_id: vehicleId,
        driver_name: driverName,
        driver_id: driverId,
      } = row.original ?? {};

      return (
        <Popover
          trigger="click"
          open={open}
          onOpenChange={(open) => {
            setOpen(open);
            if (open) {
              setOptns(getOptions(vehicleId));
            }
          }}
          content={
            <label className="flex flex-col gap-2">
              Allocate Driver
              <Dropdown
                placeholder="Select Driver"
                className="w-40 h-10"
                options={otns}
                value={driverId}
                onChange={async (dId) => {
                  await handleAssignDriver(vehicleId, dId);
                  hide();
                }}
                required
              />
              {driverId && (
                <Button
                  type="primary"
                  danger
                  onClick={async () => {
                    await handleUnassignDriver(vehicleId, driverId);
                    hide();
                  }}
                >
                  Unallocate
                </Button>
              )}
            </label>
          }
        >
          <span onClick={show}>
            {driverName ?? (
              <button className="text-blue-400 underline">Add Driver</button>
            )}
          </span>
        </Popover>
      );
    },
  },
  {
    Header: "Actions",
    Cell: ({ row }) => {
      const navigate = useNavigate();
      const handleEditClick = () => {
        navigate(`/add-fleet?modal_id=${row.original.model_id}`);
      };
      const handleViewClick = () => navigate(`vehicle/${row.values.model_id}`);

      return (
        <div className="flex gap-4">
          <button className="text-blue-400 underline" onClick={handleViewClick}>
            View
          </button>
          <button className="text-blue-400 underline" onClick={handleEditClick}>
            Edit
          </button>
        </div>
      );
    },
  },
];
