import { Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";

export const driverColumns = [
  {
    Header: "Driver id",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone_numebr",
  },
  {
    Header: "DL no",
    accessor: "dl_no",
  },
  {
    Header: "Address",
    accessor: "permanent_address",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => {
      const status = row.original.status;
      let color;
      switch (status) {
        case "Active":
          color = "green";
          break;
        case "InActive":
          color = "red";
          break;
        default:
          color = "green";
      }
      return <Tag color={color}>{status}</Tag>;
    },
  },
  {
    Header: "KYC status",
    accessor: "kyc_status",
    Cell: ({ row }) => {
      const status = row.original.kyc_status;
      const navigate = useNavigate();
      let color;
      switch (status) {
        case "DONE":
          color = "green";
          break;
        case "PENDING":
          color = "red";
          break;
        default:
          color = "green";
      }
      return (
        <Tag
          color={color}
          onClick={(e) => {
            e.stopPropagation();
            if (status === "PENDING") {
              navigate(`/update-kyc/${row.original.id}`);
            }
          }}
        >
          {status}
        </Tag>
      );
    },
  },
  {
    Header: "Vendor",
    accessor: "vendor.name",
  },
  {
    Header: "Actions",
    Cell: ({ row }) => {
      const navigate = useNavigate();
      return (
        <button
          className="text-blue-400 underline"
          onClick={(e) => {
            navigate(`/add-driver?driverId=${row.original.id}`);
            e.stopPropagation();
          }}
        >
          Edit
        </button>
      );
    },
  },
];

export const driverStatus = [
  { label: "Active", value: "Active" },
  { label: "InActive", value: "InActive" },
];
