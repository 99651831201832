import { useEffect, useState } from "react";
import { supabase } from "../backend/supabaseClient";

const useProtectedAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const { data: user, error } = await supabase.auth.getUser();
        if (error) {
          throw error;
        }
        setUser(user);
        setLoading(false);
      } catch (error) {
        setUser(null);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);
  return { user, loading };
};

export default useProtectedAuth;
