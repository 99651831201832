import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import Spinner from "../../components/atom/Spinner";
import { fetchDrivers } from "../../backend/service/driver.service";
import { driverColumns } from "../../constants/driver.constant";
import Input from "../../components/atom/Input";
import Button from "../../components/atom/Button";

const DriverList = () => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, error } = await fetchDrivers();
        if (error) {
          throw error;
        }
        setDrivers(data);
      } catch (error) {
        console.error("Error fetching driver:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: driverColumns, data: drivers });
  const { filter, handleFilterChange, applyFilter, clearFilter } = useFilters({
    setDrivers,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFilterChange(name, value);
  };

  return (
    <div className="p-5">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex justify-between py-5">
            <div className="flex flex-row items-center gap-2 w-full">
              <h2 className="text-2xl font-medium">Rider Info</h2>
              <Input
                className="ml-2 w-32"
                placeholder="Filter by phone"
                name="phone"
                value={filter?.phone}
                onChange={handleInputChange}
              />
              <Input
                className="w-32"
                placeholder="Filter by address"
                name="address"
                value={filter?.address}
                onChange={handleInputChange}
              />
              <div className="flex gap-1">
                <Button type="dashed" onClick={applyFilter}>
                  Apply
                </Button>
                <Button type="dashed" danger onClick={clearFilter}>
                  Clear
                </Button>
              </div>
            </div>
            <Link to="/add-driver">
              <Button type="primary">Add Driver</Button>
            </Link>
          </div>

          <table
            {...getTableProps()}
            style={{
              border: "1px solid #f7e7e7",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        borderBottom: "1px solid #f7e7e7",
                        background: "#dde2f7",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.values.id}
                    {...row.getRowProps()}
                    style={{
                      borderBottom: "1px solid #f7e7e7",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/drivers/${row.values.id}`)}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DriverList;

const INITIAL_FILTERS = { phone: "", address: "" };
function useFilters({ setDrivers }) {
  const [filter, setFilter] = useState(INITIAL_FILTERS);

  const handleFilterChange = (filterFor, filterValue) => {
    setFilter((prevFilter) => ({ ...prevFilter, [filterFor]: filterValue }));
  };

  const filterDriver = async (filter) => {
    try {
      const { data, error } = await fetchDrivers(filter);
      if (error) throw error;
      setDrivers(data);
    } catch (err) {
      message.error(err?.message ?? "Error filtering your query!");
    }
  };

  const applyFilter = () => filterDriver(filter);

  const clearFilter = () => {
    setFilter(INITIAL_FILTERS);
    filterDriver(INITIAL_FILTERS);
  };

  return { filter, handleFilterChange, applyFilter, clearFilter };
}
