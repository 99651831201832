import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { CarOutlined } from "@ant-design/icons";
import Spinner from "../../components/atom/Spinner";
import { fetchVendors } from "../../backend/service/vendor.service";

const columns = [
  {
    Header: "Vendor name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email_id",
  },
  {
    Header: "Phone number",
    accessor: "phone_number",
  },
  {
    Header: "Actions",
    Cell: () => <button className="text-blue-400 underline">Edit</button>,
  },
];

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    try {
      const { data, error } = await fetchVendors();
      if (error) {
        throw error;
      }
      setVendors(data);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: vendors });

  return (
    <div className="p-5">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex justify-between py-5">
            <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
              Vendors
            </h2>
            <Link to="/add-vendor">
              <Button type="primary" icon={<CarOutlined />}>
                Add Vendors
              </Button>
            </Link>
          </div>

          <table
            {...getTableProps()}
            style={{
              border: "1px solid #f7e7e7",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        borderBottom: "1px solid #f7e7e7",
                        background: "#dde2f7",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.values.model_id}
                    {...row.getRowProps()}
                    style={{ borderBottom: "1px solid #f7e7e7" }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VendorList;
