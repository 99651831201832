import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { message } from "antd";
import * as Yup from "yup";
import LoginHeroImage from "../../assets/images/LoginHeroImage.png";
import CustomInput from "../../components/atom/Input";
import Spinner from "../../components/atom/Spinner";
import { userSignUp } from "../../backend/service/auth.service";
import { useAuth } from "../../providers/auth.provider";

const registerValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .max(30, "Password cannot exceed 30 characters")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    )
    .required("New Password is required"),
});

const SignUp = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const { user, signUp } = useAuth();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registerValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: signUp,
  });

  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1 items-center">
        <div>
          <img src={LoginHeroImage} alt="" className="w-full" />
        </div>
        <div className="flex justify-center items-center">
          <div className="p-5  md:w-[80%] w-full flex flex-col gap-6 justify-center items-center">
            <h1 className="text-2xl mb-2 font-bold text-start text-sidebarbg ">
              Create a new account
            </h1>
            <p className="text-sm mb-2 text-[#ccc] text-start text-sidebarbg ">
              Welcome to Rider
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-6 w-full"
            >
              <div>
                <CustomInput
                  type="email"
                  placeholder="Enter email"
                  className="w-full py-2"
                  name="email"
                  id="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
              </div>
              <div>
                <CustomInput
                  type="password"
                  placeholder="Enter password"
                  className="w-full py-2"
                  name="password"
                  id="password"
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && formik.errors.password}
                />
              </div>
              <br />
              <button
                onClick={formik.handleSubmit}
                className="bg-[#1A48D3] hover:bg-[#6d7ff3] cursor-pointer w-full py-2 text-center rounded-xl mb-4 text-white font-medium text-xl border-0"
                type="submit"
                disabled={user.status === "PENDING"}
              >
                {user.status === "PENDING" ? <Spinner /> : "Sign up"}
              </button>
              <div className="flex justify-center text-[#6C6C6C]">
                Already have an account? &nbsp;
                <Link
                  to="/sign_in"
                  className="text-[#5D3587] underline font-semibold"
                >
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
