import React from "react";
import { Input as AntInput } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

/**
 *
 * @param {props & React.InputsHTMLAttributes<HTMLInputsElement>} props
 * @returns {JSX.Element}
 */

const Input = ({
  type,
  placeholder,
  className,
  name,
  id,
  required,
  disabled,
  value,
  onBlur,
  onChange,
  error,
}) => {
  return (
    <>
      {type === "password" ? (
        <AntInput.Password
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          type={type}
          placeholder={placeholder}
          className={className}
          name={name}
          id={id}
          disabled={disabled}
          required={required}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        <AntInput
          type={type}
          placeholder={placeholder}
          className={className}
          name={name}
          id={id}
          disabled={disabled}
          required={required}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      <p className="text-red-400 mt-1 text-sm">{error}</p>
    </>
  );
};

export default Input;
