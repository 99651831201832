import React from "react";
import { Select } from "antd";

const Dropdown = ({
  options,
  placeholder,
  filterOption,
  allowClear,
  onChange,
  onBlur,
  value,
  disabled,
  multiple,
  className,
  id,
  name,
  required,
}) => (
  <Select
    placeholder={placeholder}
    filterOption={filterOption}
    multiple={multiple}
    options={options}
    className={className}
    allowClear={allowClear}
    disabled={disabled}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    id={id}
    name={name}
    required={required}
  />
);

export default Dropdown;
