
export const maitainancesColumns = [
  {
    Header: "Service ID",
    accessor: "service_id",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Duration",
    accessor: "duration",
  },
  {
    Header: "Payment",
    accessor: "payment",
  },
  
  {
    Header: "Amount",
    accessor: "amount",
  },
];
