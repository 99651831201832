import { supabase } from "../supabaseClient";

export async function addVehicle(values) {
  const response = await supabase.from("vehicle").insert([values]);
  return response;
}

export async function updateVehicle(values, modal_id) {
  const response = await supabase
    .from("vehicle")
    .update(values)
    .eq("model_id", modal_id)
    .select();
  return response;
}

export async function deleteVehicle(modal_id) {
  const { error } = await supabase
    .from("vehicle")
    .delete()
    .eq("model_id", modal_id);
  return { error };
}

export async function fetchVehicles() {
  const response = await supabase
    .from('vehicle')
    .select(`*, driver!driver_id (id, name)`);
  return response;
}

export async function fetchSingleVehicle(model_id) {
  const response = await supabase
    .from("vehicle")
    .select()
    .eq("model_id", model_id)
    .maybeSingle();
  return response;
}
                                                                                   
export async function getDriverOptions() {
    const { data, error: vehicleError } = await supabase
        .from('vehicle')
        .select('driver!id (id)').not("driver_id", "is", null)
    const alreadyAssociatedDriverIds = data.map(v => v.driver.id)

    const { data: drivers, error: driverError } = await supabase.from("driver").select('id, name')

    const driverOptions = drivers.filter(driver => !alreadyAssociatedDriverIds.includes(driver.id)).map(({ id, name }) => ({ label: name, value: id }));
    return {data: driverOptions, error: vehicleError ?? driverError  }
}

export async function uploadVehicleImage(file) {
  const filePath = `images/${crypto.randomUUID()}__${file.name}`;
  const { data: uploadData, error: uploadError } = await supabase.storage
    .from("vehicle_image")
    .upload(filePath, file);

  if (uploadError) {
    return { data: null, error: uploadError };
  }
  const { data: urlData, error: urlError } = supabase.storage
    .from("vehicle_image")
    .getPublicUrl(filePath);
  const publicURL = urlData?.publicUrl;

  return { data: publicURL, error: urlError };
}

function extractFilePathFromUrl(url) {
  const urlObject = new URL(url);
  return urlObject.pathname.replace(
    /^\/storage\/v1\/object\/public\/vehicle_image\//,
    ""
  );
}

export async function deleteVehicleFromStorage(url) {
  const filePath = extractFilePathFromUrl(url);
  const response = await supabase.storage
    .from("vehicle_image")
    .remove([filePath]);
  return response;
}
