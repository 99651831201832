import { CloudUploadOutlined } from "@ant-design/icons";
import React, { useRef, useEffect } from "react";
import { uploadVehicleImage } from "../../../backend/service/vehicle.service";
import { message } from "antd";

const CustomDragAndDropUpload = ({
  base64Image,
  setBase64Image,
  label,
  disabled,
  name,
  id,
  required,
  onBlur,
}) => {
  const fileInputRef = useRef(null);
  const imageRef = useRef(null);

  const handleImageUpload = async (file) => {
    try {
      const { data, error } = await uploadVehicleImage(file);
      if (error) {
        message.error(error?.message);
        return;
      }
      setBase64Image(data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleImageUpload(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        imageRef.current.src = reader.result;
        imageRef.current.style.visibility = "visible";
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (base64Image) {
      imageRef.current.style.visibility = "visible";
    }
  }, [base64Image]);

  return (
    <div
      className="border-2 border-gray-500 flex justify-center items-center rounded-xl border-dotted p-4  w-full h-40"
      onClick={() => fileInputRef.current.click()}
    >
      <div className="flex justify-between items-center">
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          style={{ display: "none" }}
          disabled={disabled}
          ref={fileInputRef}
          name={name}
          id={id}
          required={required}
          onBlur={onBlur}
        />
        <img
          ref={imageRef}
          alt="Preview"
          src={base64Image || ""}
          style={{
            maxWidth: "120px",
            display: "block",
            marginBottom: "8px",
            visibility: base64Image ? "visible" : "hidden",
          }}
        />
        <div className="flex flex-col justify-center items-center">
          <CloudUploadOutlined
            style={{ fontSize: "40px", visibility: "visible" }}
          />
          <h3 style={{ cursor: "pointer" }}>{label}</h3>
        </div>
      </div>
    </div>
  );
};

export default CustomDragAndDropUpload;
