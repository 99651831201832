import { SyncOutlined } from "@ant-design/icons";
import Button from "../../components/atom/Button";
import { useAuth } from "../../providers/auth.provider";

export function VerifyingUser() {
  const { logout } = useAuth();
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <SyncOutlined className="text-2xl" spin />
      We are currently verifying your account.
      <p>Please reach out to your admin if it's taking too much time.</p>
      <Button className="mt-4" type="primary" danger onClick={logout}>
        Logout
      </Button>
    </div>
  );
}
