import { supabase } from "../supabaseClient";

export async function fetchDrivers(filters = {}) {
  let query = supabase.from("driver").select("*, vendor(name)");

  if (filters?.phone || filters?.address)
    query = query.or(
      `phone_numebr.ilike.${filters.phone ?? "''"},permanent_address.ilike.${
        filters.address ?? "''"
      }`
    );

  const response = await query;
  return response;
}

export async function addDriver(values) {
  const response = await supabase.from("driver").insert([values]);
  return response;
}

export async function fetchDriverDetails(driver_id) {
  const response = await supabase
    .from("driver")
    .select()
    .eq("id", driver_id)
    .maybeSingle();
  return response;
}

export async function updateDriver(values, driver_id) {
  const response = await supabase
    .from("driver")
    .update(values)
    .eq("id", driver_id)
    .select();
  return response;
}

export async function uploadKycDetails(values, driver_id) {
  const response = await supabase
    .from("driver")
    .update(values)
    .eq("id", driver_id)
    .select();
  return response;
}

export async function deleteDriver(driver_id) {
  const { error } = await supabase.from("driver").delete().eq("id", driver_id);
  return { error };
}
