import { supabase } from "../supabaseClient";

export async function addVendors(values) {
  const response = await supabase.from("vendor").insert([values]);
  return response;
}

export async function fetchVendors() {
  const response = await supabase.from("vendor").select("*");
  return response;
}
