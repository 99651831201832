import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";

const PopUpModal = ({ title, open, onOk, onCancel, children }) => {
  return (
    <Modal
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Save"
    >
      {children}
    </Modal>
  );
};

PopUpModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default PopUpModal;