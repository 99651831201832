import React, { createContext, useContext, useEffect, useState } from "react";
import { useTable } from "react-table";
import Spinner from "../../components/atom/Spinner";
import {
  getUserRolesExcludingAdmin,
  updateUserRole,
} from "../../backend/service/user.service";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Popover, Tooltip, message } from "antd";
import Button from "../../components/atom/Button";
import { userRole } from "../../constants/auth.constant";

const roleMapFormatter = {
  SUPERADMIN: "Super Admin",
  SUPERVISOR: "Supervisor",
};

const columns = [
  {
    Header: "User Email",
    accessor: "email",
  },
  {
    Header: "User Role",
    Cell: ({ row }) => {
      const { role } = row.original ?? {};
      const userRole = role?.role
        ? roleMapFormatter[role?.role] ?? "Not Assigned"
        : "Not Assigned";

      return userRole;
    },
  },
  {
    Header: "User Verified",
    Cell: ({ row }) => {
      const [open, setOpen] = useState(false);

      const { updateVerification } = useUsersTable();

      const show = () => setOpen(true);
      const hide = () => setOpen(false);

      const { role_id: roleId, id, role } = row.original ?? {};
      const isSuperadmin = role?.role === userRole.superAdmin;

      const handleVerification = async () => {
        updateVerification({ role_id: roleId ? null : 2 }, id);
        hide();
      };

      return (
        <div className="flex gap-3">
          {roleId ? (
            <CheckCircleFilled className="text-green-600" />
          ) : (
            <CloseCircleFilled className="text-red-600" />
          )}
          {isSuperadmin ? (
            <Tooltip title="Admin User">
              <UserOutlined />
            </Tooltip>
          ) : (
            <Popover
              trigger="click"
              open={open}
              onOpenChange={setOpen}
              content={
                <label className="flex flex-col gap-2">
                  Change Verification Status
                  <Button
                    type="primary"
                    danger={roleId}
                    onClick={handleVerification}
                  >
                    {roleId ? "Unverify" : "Verify"} User
                  </Button>
                </label>
              }
            >
              <span onClick={show}>
                <EditFilled className="cursor-pointer" />
              </span>
            </Popover>
          )}
        </div>
      );
    },
  },
];

export function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const { data, error } = await getUserRolesExcludingAdmin();
      if (error) throw new Error(error);

      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: users });

  return (
    <div className="p-5">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex justify-between py-5">
            <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
              Users
            </h2>
          </div>

          <UsersProvider setUsers={setUsers}>
            <table
              {...getTableProps()}
              style={{
                border: "1px solid #f7e7e7",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          borderBottom: "1px solid #f7e7e7",
                          background: "#dde2f7",
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={row.values.model_id}
                      {...row.getRowProps()}
                      style={{ borderBottom: "1px solid #f7e7e7" }}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </UsersProvider>
        </div>
      )}
    </div>
  );
}

const UsersContext = createContext({
  updateVerification: () => null,
});

const UsersProvider = ({ children, setUsers }) => {
  const updateVerification = async (payload, roleId) => {
    try {
      const { data, error } = await updateUserRole(payload, roleId);
      if (error) {
        throw error;
      }

      setUsers((prev) =>
        prev.map((user) => (user.id === roleId ? data[0] : user))
      );
      message.success("Successfully updated user verification status.");
    } catch (error) {
      message.error("Error updating user verification status!");
    }
  };

  return (
    <UsersContext.Provider value={{ updateVerification }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersTable = () => {
  const ctx = useContext(UsersContext);

  if (!ctx) throw new Error("UsersContext not in scope!");
  return ctx;
};
