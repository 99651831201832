import React from "react";
import { Button as AntButton } from "antd";

/**
 * @typedef {Object} Props
 * @property {"primary" | "secondary" | "danger"} [variant]
 */
/**
 * Button Wrapper Component
 * @param {Props & React.ButtonHTMLAttributes<HTMLButtonElement>} props
 * @returns {JSX.Element}
 */

const Button = ({ type = "button", onClick, children, ...rest }) => {
  return (
    <>
      <AntButton type={type} onClick={onClick} {...rest}>
        {children}
      </AntButton>
    </>
  );
};

export default Button;
