import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Upload, message } from "antd";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import CustomInput from "../../components/atom/Input";
import CustomButton from "../../components/atom/Button/index";
import Dropdown from "../../components/atom/Dropdown/index";
import {
  addVehicle,
  deleteVehicle,
  fetchSingleVehicle,
  updateVehicle,
  uploadVehicleImage,
} from "../../backend/service/vehicle.service";
import ConfirmPopup from "../../components/atom/PopConfirm";

const { Dragger } = Upload;

const VehicleForm = () => {
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const modalId = params.get("modal_id");

  const navigate = useNavigate();

  const initialFormState = {
    model_id: vehicle?.model_id ?? "",
    vehicle_model: vehicle?.vehicle_model ?? "",
    category: vehicle?.category ?? "",
    date_added: vehicle?.date_added ?? "",
    vehicle_number: vehicle?.vehicle_number ?? "",
    vehicle_image: vehicle?.vehicle_image ?? "",
  };

  const formik = useFormik({
    initialValues: initialFormState,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        if (modalId) {
          const { data, error } = await updateVehicle(values, modalId);
          if (error) {
            throw error;
          }
          message.success("Vehicle updated successfully");
          navigate("/", { replace: true });
        } else {
          setLoading(true);
          const { data, error } = await addVehicle(values);
          if (error) {
            throw error;
          }
          message.success("Vehicle added successfully");
          navigate("/", { replace: true });
        }
      } catch (error) {
        message.error(error?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageUpload = async (file) => {
    try {
      setLoading(true);
      const { data, error } = await uploadVehicleImage(file);
      if (error) {
        message.error(error?.message);
        return;
      }
      formik.setFieldValue("vehicle_image", data);
    } catch (error) {
      message.error("Error in uploading image");
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicle = async () => {
    if (!modalId) return;
    try {
      const { data, error } = await fetchSingleVehicle(modalId);
      if (error) {
        throw error;
      }
      setVehicle(data);
    } catch (error) {
      console.error("Error fetching vehicles:", error.message);
    }
  };
  useEffect(() => {
    fetchVehicle();
  }, []);

  const handleFleetDelete = async () => {
    try {
      const { error } = await deleteVehicle(modalId);
      if (error) {
        throw error;
      }
      message.success("Vehicle deleted successfully !");
      navigate("/");
    } catch (error) {
      message.error("Error in deleting vehicles!");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="p-5">
      <div className="py-5 flex justify-between ">
        <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
          {modalId ? "Edit Fleet" : "Add Fleet"}
        </h2>
        {modalId && (
          <ConfirmPopup
            title="Delete fleet"
            description="Are you sure you want to delete fleet?"
            onConfirm={handleFleetDelete}
          >
            <CustomButton
              type=""
              danger
              icon={<DeleteOutlined />}
              onClick={() => {}}
            >
              Delete Fleet
            </CustomButton>
          </ConfirmPopup>
        )}
      </div>
      <div className=" grid md:grid-cols-2 grid-cols-1 gap-6 mb-6">
        <label>
          Model ID:
          <CustomInput
            type="text"
            placeholder="Enter model id "
            className="w-full py-2"
            name="model_id"
            id="model_id"
            required
            value={formik.values.model_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.model_id && formik.errors.model_id}
          />
        </label>
        <label>
          Vehicle Model:
          <CustomInput
            type="text"
            placeholder="Enter model id "
            className="w-full py-2"
            name="vehicle_model"
            id="vehicle_model"
            required
            value={formik.values.vehicle_model}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.vehicle_model && formik.errors.vehicle_model}
          />
        </label>
        <label>
          Category:
          <CustomInput
            type="text"
            placeholder="Enter category "
            className="w-full py-2"
            name="category"
            id="category"
            required
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.category && formik.errors.category}
          />
        </label>
        <label>
          Date Added:
          <CustomInput
            type="date"
            placeholder="Select date "
            className="w-full py-2"
            name="date_added"
            id="date_added"
            required
            value={formik.values.date_added}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.date_added && formik.errors.date_added}
          />
        </label>
        <label>
          Vehicle Number:
          <CustomInput
            type="text"
            placeholder="Enter vehicle no"
            className="w-full py-2"
            name="vehicle_number"
            id="vehicle_number"
            required
            value={formik.values.vehicle_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.vehicle_number && formik.errors.vehicle_number
            }
          />
        </label>
      </div>
      <label>
        Vehicle Image :
        <div className="md:w-[70%] w-full">
          <Dragger
            beforeUpload={(file) => {
              handleImageUpload(file);
              return false;
            }}
            multiple={false}
            maxCount={1}
          >
            <div className="flex justify-around">
              {formik.values.vehicle_image ? (
                <img
                  src={formik.values.vehicle_image}
                  alt=""
                  width={200}
                  className="h-40 object-cover "
                />
              ) : (
                <p>No Image available</p>
              )}
              <div className="items-center flex flex-col justify-center">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </div>
            </div>
          </Dragger>
        </div>
      </label>
      <div className="flex justify-end">
        <CustomButton
          type="primary"
          disabled={loading}
          onClick={formik.handleSubmit}
        >
          {loading ? "Loading ..." : "Add Vehicle"}
        </CustomButton>
      </div>
    </form>
  );
};

export default VehicleForm;
