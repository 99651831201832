import { createContext, useContext, useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  getUserForAuth,
  getUserRole,
  postUserRole,
  signOut,
  userSignIn,
  userSignUp,
} from "../backend/service/auth.service";
import { cookieKey } from "../constants/cookies.constant";

const AuthContext = createContext();

const state = {
  IDLE: "IDLE",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState({
    status: state.IDLE,
    data: null,
    error: {},
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) fetchUser();
  }, [user]);

  async function fetchUser() {
    try {
      const { data: user, error } = await getUserForAuth();
      if (error) {
        throw error;
      }
      setUser(user);
    } catch (error) {
      setUser(null);
    }
  }

  function validate() {
    if (!user) {
      return null;
    }
  }

  async function signIn(values) {
    try {
      setUser({ status: state.PENDING });
      const { data, error } = await userSignIn(values);
      const { data: userRole } = await getUserRole(data?.user?.id);

      Cookies.set(cookieKey.userRole, userRole[0]?.role?.role ?? "");

      if (error) throw error;

      setUser({ status: state.SUCCESS, data });

      message.success("Logged in successfully");
      navigate("/", { replace: true });
    } catch (error) {
      message.error(error?.message);
      setUser({ status: state.ERROR, error, data: null });
    }
  }

  async function signUp(values) {
    try {
      setUser({ status: state.PENDING });
        const { data, error } = await userSignUp(values);
        if (error) throw error;

      const { error: rolePostError } = await postUserRole({
        email: values.email,
        role_id: null,
        user_id: data.user.id,
      });
      if (rolePostError) throw rolePostError;

      setUser({ status: state.SUCCESS, data });
      message.success(
        "Sucessfully created account. Please check your email to verify your account!"
      );
      navigate("/sign_in");
    } catch (error) {
      setUser({ status: state.ERROR, error, data: null });
      message.error(error.message);
    }
  }

  async function logout() {
    try {
      await signOut();
      navigate("/sign_in");
      Cookies.remove(cookieKey.userRole);
    } catch (error) {
      message.error("Error logging out:", error.message);
    }
  }

  return (
    <AuthContext.Provider value={{ user, validate, signIn, signUp, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const authCtx = useContext(AuthContext);
  if (!authCtx)
    throw new Error("Auth context not accessible in the current scope.");
  return authCtx;
}
