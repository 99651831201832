import React, { useState } from "react";
import { useFormik } from "formik";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/atom/Input";
import CustomButton from "../../components/atom/Button/index";
import { addVendors } from "../../backend/service/vendor.service";

const initialFormState = {
  name: "",
  email_id: "",
  phone_number: "",
};

const VendorForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialFormState,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data, error } = await addVendors(values);
        if (error) {
          throw error;
        }
        message.success("Vendor added successfully");
        navigate("/vendor", { replace: true });
      } catch (error) {
        message.error(error?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="p-5">
      <div className=" grid md:grid-cols-2 grid-cols-1 gap-6">
        <label>
          Vendor name
          <CustomInput
            type="text"
            placeholder="Enter name"
            className="w-full py-2"
            name="name"
            id="name"
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
          />
        </label>
        <label>
          Email :
          <CustomInput
            type="text"
            placeholder="Enter email"
            className="w-full py-2"
            name="email_id"
            id="email_id"
            required
            value={formik.values.email_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email_id && formik.errors.email_id}
          />
        </label>
        <label>
          Phone Number:
          <CustomInput
            type="text"
            placeholder="Enter phone "
            className="w-full py-2"
            name="phone_number"
            id="phone_number"
            required
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone_number && formik.errors.phone_number}
          />
        </label>
      </div>
      <div className="flex justify-end">
        <CustomButton
          type="primary"
          disabled={loading}
          onClick={formik.handleSubmit}
        >
          {loading ? "Loading ..." : "Add Vendor"}
        </CustomButton>
      </div>
    </form>
  );
};

export default VendorForm;
