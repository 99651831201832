import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { driverStatus } from "../../constants/driver.constant";
import CustomInput from "../../components/atom/Input";
import CustomButton from "../../components/atom/Button/index";
import {
  addDriver,
  fetchDriverDetails,
  updateDriver,
} from "../../backend/service/driver.service";
import Dropdown from "../../components/atom/Dropdown";
import { fetchVendors } from "../../backend/service/vendor.service";

const DriverForm = () => {
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [driver, setDriver] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const driverId = params.get("driverId");

  const initialFormState = {
    name: driver?.name ?? "",
    phone_numebr: driver?.phone_numebr ?? "",
    dl_no: driver?.dl_no ?? "",
    permanent_address: driver?.permanent_address ?? "",
    status: driver?.status ?? "",
    vendor_id: driver?.vendor_id ?? "",
  };

  const formik = useFormik({
    initialValues: initialFormState,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (driverId) {
          const { data, error } = await updateDriver(values, driverId);
          if (error) {
            throw error;
          }
          message.success("Driver updated successfully");
          navigate("/drivers", { replace: true });
        } else {
          const { data, error } = await addDriver(values);
          if (error) {
            throw error;
          }
          message.success("Driver added successfully");
          navigate("/drivers", { replace: true });
        }
      } catch (error) {
        message.error(error?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, error } = await fetchVendors();
        if (error) {
          throw error;
        }
        const options = data.map((item) => ({
          label: item.name,
          value: item.vendor_id,
        }));
        setVendors(options);
      } catch (error) {
        console.error("Error fetching vendors:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDriver = async () => {
      try {
        const { data, error } = await fetchDriverDetails(driverId);
        if (error) {
          throw error;
        }
        setDriver(data);
      } catch (error) {
        console.error("Error fetching drivers:", error.message);
      }
    };
    fetchDriver();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className="p-5">
      <h2 className="text-2xl font-medium self-stretch max-md:max-w-full py-5">
        {driverId ? "Update driver details" : "Assign Vehicle"}
      </h2>
      <div className=" grid md:grid-cols-2 grid-cols-1 gap-6">
        <label>
          Driver name:
          <CustomInput
            type="text"
            placeholder="Enter name "
            className="w-full py-2"
            name="name"
            id="name"
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
          />
        </label>
        <label>
          Phone Number:
          <CustomInput
            type="number"
            placeholder="Enter number "
            className="w-full py-2"
            name="phone_numebr"
            id="phone_numebr"
            required
            value={formik.values.phone_numebr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone_numebr && formik.errors.phone_numebr}
          />
        </label>
        <label>
          DL no:
          <CustomInput
            type="text"
            placeholder="Enter dl no "
            className="w-full py-2"
            name="dl_no"
            id="dl_no"
            required
            value={formik.values.dl_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.dl_no && formik.errors.dl_no}
          />
        </label>
        <label>
          Permanent Address:
          <CustomInput
            type="text"
            placeholder="Enter address "
            className="w-full py-2"
            name="permanent_address"
            id="permanent_address"
            required
            value={formik.values.permanent_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.permanent_address &&
              formik.errors.permanent_address
            }
          />
        </label>
        <label>
          Status:
          <Dropdown
            placeholder="Select status"
            className="w-full h-10"
            name="status"
            id="status"
            required
            options={driverStatus}
            onChange={(value) => formik.setFieldValue("status", value)}
            onBlur={formik.handleBlur}
            value={formik.values.status}
          />
        </label>
        <label>
          Select vendor:
          <Dropdown
            placeholder="Select vendor"
            className="w-full h-10"
            name="vendor_id"
            id="vendor_id"
            required
            options={vendors}
            onChange={(value) => formik.setFieldValue("vendor_id", value)}
            onBlur={formik.handleBlur}
            value={formik.values.vendor_id}
          />
        </label>
      </div>
      <div className="flex justify-end mt-8">
        <CustomButton
          type="primary"
          disabled={loading}
          onClick={formik.handleSubmit}
        >
          {loading ? "Loading ..." : driverId ? "Update Driver" : "Add Driver"}
        </CustomButton>
      </div>
    </form>
  );
};

export default DriverForm;
