import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useProtectedAuth from "../hooks/useProtectedAuth";
import Spinner from "../components/atom/Spinner";

const AuthenticationRoute = () => {
  const navigate = useNavigate();
  const { user, loading } = useProtectedAuth();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }
  if (user) {
    navigate("/", { replace: true });
    return null;
  }

  return <Outlet />;
};

export default AuthenticationRoute;
