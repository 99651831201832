import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import Spinner from "../../components/atom/Spinner";
import {
  deleteDriver,
  fetchDriverDetails,
  updateDriver,
} from "../../backend/service/driver.service";
import Button from "../../components/atom/Button";
import CustomInput from "../../components/atom/Input";
import { driverStatus } from "../../constants/driver.constant";
import Dropdown from "../../components/atom/Dropdown";
import { fetchVendors } from "../../backend/service/vendor.service";
import ProfileUpload from "../../components/atom/ProfileUpload";
import CustomDragAndDropUpload from "../../components/atom/CustomDragAndDropUpload";
import ConfirmPopup from "../../components/atom/PopConfirm";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Driver name is required"),
  phone_numebr: Yup.string().required("Phone number is required"),
  dl_no: Yup.string(),
  permanent_address: Yup.string(),
  status: Yup.string(),
  vendor_id: Yup.string().required("Vendor is required"),
  selfie: Yup.mixed().required("Selfie is required"),
  pan_number: Yup.string().required("Pan number is required"),
  aadhaar_number: Yup.string().required("Aadhar number is required"),
  drivers_license: Yup.string().required("License is required"),
  aadhaar_card: Yup.string().required("Aadhar card is required"),
});

const SingleDriver = () => {
  const [driver, setDriver] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  const [file, setFile] = useState({ file: null, preview: null });
  const [aadharImage, setAadharImage] = useState(null);
  const [dlImage, setDlImage] = useState(null);
  const { driver_id } = useParams();
  const navigate = useNavigate();

  const initialFormState = {
    name: driver?.name ?? "",
    phone_numebr: driver?.phone_numebr ?? "",
    dl_no: driver?.dl_no ?? "",
    permanent_address: driver?.permanent_address ?? "",
    status: driver?.status ?? "",
    vendor_id: driver?.vendor_id ?? "",
    aadhaar_number: driver?.aadhaar_number ?? "",
    pan_number: driver?.pan_number ?? "",
    selfie: file.preview ?? "",
    drivers_license: driver?.drivers_license ?? "",
    aadhaar_card: driver?.aadhaar_card ?? "",
    kyc_status: "DONE" ?? "",
  };

  const formik = useFormik({
    initialValues: initialFormState,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (driver_id) {
          const { data, error } = await updateDriver(values, driver_id);
          if (error) {
            throw error;
          }
          message.success("Driver updated successfully");
          navigate("/drivers", { replace: true });
        }
      } catch (error) {
        message.error(error?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchDriver = async () => {
      try {
        setLoading(true);
        const { data, error } = await fetchDriverDetails(driver_id);
        if (error) {
          throw error;
        }
        setDriver(data);
        setFile({ file: "selfie", preview: data?.selfie });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching drivers:", error.message);
        setLoading(false);
      }
    };
    fetchDriver();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, error } = await fetchVendors();
        if (error) {
          throw error;
        }
        const options = data.map((item) => ({
          label: item.name,
          value: item.vendor_id,
        }));
        setVendors(options);
      } catch (error) {
        console.error("Error fetching vendors:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDriverDelete = async () => {
    try {
      const { error } = await deleteDriver(driver_id);
      if (error) {
        throw error;
      }
      message.success("Driver deleted successfully !");
      navigate("/drivers");
    } catch (error) {
      message.error("Error in deleting vehicles!");
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className=" p-5">
          <h2 className="flex justify-between text-2xl font-medium self-stretch max-md:max-w-full py-5">
            {edit ? "Driver details" : "Edit details"}
            <div className="flex flex-row gap-2">
              <ConfirmPopup
                title="Delete fleet"
                description="Are you sure you want to delete fleet?"
                onConfirm={handleDriverDelete}
              >
                <Button
                  type=""
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {}}
                >
                  Driver
                </Button>
              </ConfirmPopup>
              <Button
                icon={<EditOutlined />}
                type="primary"
                ghost={edit}
                onClick={() => setEdit(!edit)}
              >
                Edit
              </Button>
            </div>
          </h2>
          <div className="bg-white py-10 px-5 shadow-md rounded-md">
            <div>
              <ProfileUpload
                file={file}
                setFile={(file) => {
                  setFile(file);
                  formik.setFieldValue("selfie", file.preview);
                }}
                disabled={edit}
                id="selfie"
                name="selfie"
              />
              <p className="text-red-400 mt-1 text-sm">
                {formik.touched.selfie && formik.errors.selfie}
              </p>
            </div>
            <div className=" grid md:grid-cols-2 grid-cols-1 gap-6 mt-5 w-full">
              <label>
                Rider name:
                <CustomInput
                  type="text"
                  placeholder="Enter name "
                  className="w-full py-2"
                  name="name"
                  id="name"
                  disabled={edit}
                  required
                  value={formik?.values?.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              </label>
              <label>
                Phone Number:
                <CustomInput
                  type="number"
                  placeholder="Enter number "
                  className="w-full py-2"
                  name="phone_numebr"
                  id="phone_numebr"
                  disabled={edit}
                  required
                  value={formik?.values?.phone_numebr}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phone_numebr && formik.errors.phone_numebr
                  }
                />
              </label>
              <label>
                DL no:
                <CustomInput
                  type="text"
                  placeholder="Enter dl no "
                  className="w-full py-2"
                  name="dl_no"
                  id="dl_no"
                  required
                  disabled={edit}
                  value={formik?.values?.dl_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dl_no && formik.errors.dl_no}
                />
              </label>
              <label>
                Permanent Address:
                <CustomInput
                  type="text"
                  placeholder="Enter address "
                  className="w-full py-2"
                  name="permanent_address"
                  id="permanent_address"
                  required
                  disabled={edit}
                  value={formik?.values?.permanent_address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.permanent_address &&
                    formik.errors.permanent_address
                  }
                />
              </label>
              <label>
                Status:
                <Dropdown
                  placeholder="Select status"
                  className="w-full h-10"
                  name="status"
                  id="status"
                  disabled={edit}
                  required
                  options={driverStatus}
                  onChange={(value) => formik.setFieldValue("status", value)}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.status}
                />
              </label>
              <label>
                Select vendor:
                <Dropdown
                  placeholder="Select vendor"
                  className="w-full h-10"
                  name="vendor_id"
                  id="vendor_id"
                  disabled={edit}
                  required
                  options={vendors}
                  onChange={(value) => formik.setFieldValue("vendor_id", value)}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.vendor_id}
                />
              </label>
              <label>
                Pan no:
                <CustomInput
                  type="text"
                  placeholder="Enter pan no "
                  className="w-full py-2"
                  name="pan_number"
                  id="pan_number"
                  required
                  disabled={edit}
                  value={formik?.values?.pan_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.pan_number && formik.errors.pan_number}
                />
              </label>
              <label>
                Aadhar no:
                <CustomInput
                  type="text"
                  placeholder="Enter aadhar "
                  className="w-full py-2"
                  name="aadhaar_number"
                  id="aadhaar_number"
                  required
                  disabled={edit}
                  value={formik?.values?.aadhaar_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.aadhaar_number &&
                    formik.errors.aadhaar_number
                  }
                />
              </label>
              <div className="w-full">
                <CustomDragAndDropUpload
                  label="Driver Adhar Upload"
                  setBase64Image={(file) => {
                    setAadharImage(file);
                    formik.setFieldValue("aadhaar_card", file);
                  }}
                  base64Image={driver?.aadhaar_card}
                  disabled={edit}
                  id="aadhaar_card"
                  name="aadhaar_card"
                />
                <p className="text-red-400 mt-1 text-sm">
                  {formik.touched.aadhaar_card && formik.errors.aadhaar_card}
                </p>
              </div>
              <div className="w-full">
                <CustomDragAndDropUpload
                  label="Driver License Upload"
                  setBase64Image={(file) => {
                    setDlImage(file);
                    formik.setFieldValue("drivers_license", file);
                  }}
                  base64Image={driver?.drivers_license}
                  disabled={edit}
                  id="drivers_license"
                  name="drivers_license"
                />
                <p className="text-red-400 mt-1 text-sm">
                  {formik.touched.drivers_license &&
                    formik.errors.drivers_license}
                </p>
              </div>
            </div>
            <div className="flex justify-end mt-8">
              <Button
                type="primary"
                disabled={loading || edit}
                onClick={formik.handleSubmit}
              >
                {loading
                  ? "Loading ..."
                  : driver_id
                  ? "Update Driver"
                  : "Add Driver"}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default SingleDriver;
