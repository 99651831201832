import { supabase } from "../supabaseClient";

export async function getUserRolesExcludingAdmin() {
  const response = await supabase.from("user_roles").select("*, role(role)");
  return response;
}

export async function updateUserRole(payload, id) {
  const response = await supabase
    .from("user_roles")
    .update(payload)
    .eq("id", id)
    .select();
  return response;
}
