import React from "react";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { message } from "antd";
import { uploadVehicleImage } from "../../../backend/service/vehicle.service";

const ProfileUpload = ({
  file,
  setFile,
  disabled,
  name,
  id,
  required,
  onBlur,
}) => {
  const handleImageUpload = async (file) => {
    try {
      const { data, error } = await uploadVehicleImage(file);
      if (error) {
        message.error(error?.message);
        return;
      }
      setFile({
        file: "",
        preview: data,
      });
    } catch (error) {}
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleImageUpload(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <div className="flex flex-col items-start">
      {/* Preview */}
      <div className="bg-gray-400 rounded-full w-16 h-16 flex items-center justify-center mb-1 overflow-hidden">
        {file.preview ? (
          <img
            src={file.preview}
            alt="Profile Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <UserOutlined style={{ fontSize: "30px" }} />
        )}
      </div>

      <input
        type="file"
        name={name}
        id={id}
        required={required}
        onBlur={onBlur}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".jpg,.jpeg,.png"
        disabled={disabled}
      />
      {!disabled && (
        <h3
          className="cursor-pointer text-blue-500 flex gap-3"
          onClick={() => document.querySelector('input[type="file"]').click()}
        >
          Upload Profile <UploadOutlined />
        </h3>
      )}
    </div>
  );
};

export default ProfileUpload;
