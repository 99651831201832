// AllocationTable.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import { Button, message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import PopUpModal from "../../components/atom/PopUpModal";
import CustomInput from "../../components/atom/Input";
import CustomDatePicker from "../../components/atom/DatePicker";
import {
  fetchAllocations,
  addAllocationHistory,
} from "../../backend/service/allocation.service";
import { allocationColumns } from "../../constants/allocation.constant";
import Spinner from "../../components/atom/Spinner";

const AllocationTable = () => {
  const [allocations, setAllocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { vehicle_id } = useParams();

  const fetchAllocationsData = async () => {
    setLoading(true);
    const abortController = new AbortController();
    try {
      const signal = abortController.signal;
      if (vehicle_id) {
        const { data, error } = await fetchAllocations(vehicle_id, { signal });
        if (error) {
          throw error;
        }
        setAllocations(data);
      }
    } catch (error) {
      console.error("Error fetching allocations:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchAllocationsData(signal);
    return () => {
      controller.abort();
    };
  }, [vehicle_id]);

  const allocationsTable = useTable({
    columns: allocationColumns,
    data: allocations,
  });

  return (
    <div className="flex flex-col p-6">
      <div className="flex justify-between py-5">
        <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
          Allocation History
        </h2>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <table
          {...allocationsTable.getTableProps()}
          style={{
            border: "1px solid #f7e7e7",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            {allocationsTable.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "1px solid #f7e7e7",
                      background: "#dde2f7",
                      padding: "8px",
                      textAlign: "left",
                      fontSize: "medium",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...allocationsTable.getTableBodyProps()}>
            {allocationsTable.rows.map((row) => {
              allocationsTable.prepareRow(row);
              return (
                <tr
                  key={row.values.model_id}
                  {...row.getRowProps()}
                  style={{
                    borderBottom: "1px solid #f7e7e7",
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AllocationTable;
