import moment from "moment";

export const allocationColumns = [
  {
    Header: "Allocated To",
    accessor: "driver.name",
  },
  {
    Header: "Mobile Number",
    accessor: "driver.phone_numebr",
  },
  {
    Header: "Vehicle Number",
    accessor: "vehicle.vehicle_number",
  },
  {
    Header: "Logged at",
    accessor: "created_at",
    Cell: ({ row }) => {
      const createdAt = row.original.created_at;
      return moment(createdAt).format('MMMM D, YYYY');
    },
  },
];
