import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleVehicle } from "../../backend/service/vehicle.service";
import Spinner from "../../components/atom/Spinner";
import MaintenanceTable from "./maintenance_table";
import AllocationTable from "./allocation_table";

const SingleVehicle = () => {
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(false);
  const { vehicle_id } = useParams();

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data, error } = await fetchSingleVehicle(vehicle_id);
      if (error) {
        throw error;
      }
      setVehicle(data);
    } catch (error) {
      console.error("Error fetching vehicle:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [vehicle_id]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col p-6 gap-6">
          <h2 className="text-2xl font-medium self-stretch max-md:max-w-full">
            Model ID {vehicle?.model_id}
          </h2>
          <div className="bg-white flex justify-around shadow-lg p-5">
            {vehicle?.vehicle_image ? (
              <img src={vehicle.vehicle_image} alt="Vehicle" width={250} />
            ) : (
              <p>No image available</p>
            )}
            <div className="flex flex-col gap-6">
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Model ID:</p>
                <h1 className="text-lg font-medium">{vehicle?.model_id}</h1>
              </div>
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Vehicle Model:</p>
                <h1 className="text-lg font-medium">
                  {vehicle?.vehicle_model}
                </h1>
              </div>
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Category:</p>
                <h1 className="text-lg font-medium">{vehicle?.category}</h1>
              </div>
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Date Added:</p>
                <h1 className="text-lg font-medium">{vehicle?.date_added}</h1>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Vehicle Number:</p>
                <h1 className="text-lg font-medium">
                  {vehicle?.vehicle_number}
                </h1>
              </div>
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Allocation Status:</p>
                <h1 className="text-lg font-medium">
                  {vehicle?.allocation_status}
                </h1>
              </div>
              <div className="flex gap-4">
                <p className="text-lg text-[#787575]">Allocated Driver:</p>
                <h1 className="text-lg font-medium">
                  {vehicle?.allocated_driver}
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ------------Maintenance table------------------ */}
      <MaintenanceTable />
      {/* --------------------Allocation table ------------------- */}
      <AllocationTable />
    </>
  );
};

export default SingleVehicle;
